import type { Theme } from '@mui/material/styles';

import { type IExtendedStyleProps } from '@themes/types/extended';
import { getColors } from '@themes/utils/getColors';

function getColorStyle({ color, theme }: IExtendedStyleProps) {
  const colors = getColors(theme, color);
  const { main, light, lighter } = colors;

  return {
    color: main,
    borderColor: light,
    backgroundColor: lighter,
    '& .MuiChip-deleteIcon': {
      color: main,
      '&:hover': {
        color: light,
      },
    },
  };
}

export default function Chip(theme: Theme) {
  const defaultLightChip = getColorStyle({ theme, color: 'secondary' });

  return {
    MuiChip: {
      styleOverrides: {
        sizeLarge: {
          height: 40,
          fontSize: '1rem',
        },
        sizeSmall: {
          height: 22,
          fontSize: '0.75rem',
        },
        root: {
          borderRadius: 4,
          '&.MuiChip-colorInfo': {},
          '&.MuiChip-colorPrimary': {},
          '&.MuiChip-colorSecondary': { ...defaultLightChip },
          '&:active': {
            boxShadow: 'none',
          },
          '&.MuiChip-colorError': {
            color: theme.palette.error.darker,
            borderColor: theme.palette.error.darker,
            backgroundColor: theme.palette.error.lighter,
            '& .MuiChip-deleteIcon': {
              color: theme.palette.error.main,
              '&:hover': {
                color: theme.palette.error.light,
              },
            },
          },
          '&.MuiChip-colorWarning': {
            color: theme.palette.warning.main,
            borderColor: theme.palette.warning.light,
            backgroundColor: theme.palette.warning.lighter,
            '& .MuiChip-deleteIcon': {
              color: theme.palette.warning.main,
              '&:hover': {
                color: theme.palette.warning.light,
              },
            },
          },
          '&.MuiChip-colorSuccess': {
            color: theme.palette.success.main,
            borderColor: theme.palette.success.light,
            backgroundColor: theme.palette.success.lighter,
            '& .MuiChip-deleteIcon': {
              color: theme.palette.success.main,
              '&:hover': {
                color: theme.palette.success.light,
              },
            },
          },
        },
      },
    },
  };
}
